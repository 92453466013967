import React, {useState, useEffect} from "react";
import '../pages.css'
import {ImageBox, ArticleBox, ArticleBoxText} from '../../components';
import {FontAwesomeIcon as Icon} from "@fortawesome/react-fontawesome";
import {Link} from 'react-router-dom'
import {getService} from "../../service/RestService";
import {URL_PAGINATE_ARTICLE_ALL, URL_PAGINATE_ARTICLE_CATEGORY, URL_ALL_CATEGORY} from "../../service/url";
import placeholder from "../../assets/images/graphic-placeholder.png";

function ArticleList(){
    //Constructor
    useEffect(() => {
        async function mount(){
            await fetchArticlesRecent();
            await fetchCategory();
        }
        mount();
    },[]);

    //State
    const [recentArticles, setRecentArticles] = useState([]);
    const [categoryArticles, setCategoryArticles] = useState([]);

    //Function
    const fetchArticlesRecent = async() => {
        const result = await getService(URL_PAGINATE_ARTICLE_ALL(1,10));
        const temp = [];
        result.page.content.map(article => (
            article.category.id !== 2? temp.push(article) : null
        ));
        setRecentArticles(temp.slice(0,4))
    };
    const fetchCategory = async() => {
        const result = await getService(URL_ALL_CATEGORY);
        await fetchArticleCategories(result.categories);
    };
    const fetchArticleCategories = async(categoryList) => {
        let temp = [];
        let result;
        try{
            for (const category of categoryList) {
                result = await getService(URL_PAGINATE_ARTICLE_CATEGORY(1,3,category.id));
                temp.push({
                    categoryId: category.id,
                    categoryName: category.categoryName,
                    articles: result.page.content
                });
            }
        }catch (e) {
            console.log(e);
            console.log('ERROR');
        }

        setCategoryArticles(temp);
    };
    const returnVisibility = (visibility) => {
        if(visibility === 'public') return 'Public';
        else if(visibility === 'free_member') return 'Member Only';
        else if(visibility === 'paid_member') return 'Premium Members';
    };

    const windowWidth = window.innerWidth;
    return(
        <div className={'content'}>
            {/*Recent Articles*/}
            {recentArticles.length>0?
                <div className={'articleList-recent'}>
                    <div className={'home-section-title'}>
                        <div className={'home-section-title-strip'}>
                            <div className={'home-section-title-red-strip'}/>
                        </div>
                        <div className={'home-section-title-text text-bold'}>INFORMATION & ARTICLES</div>
                    </div>
                    <div className={'home-articles-content'}>
                        <div className={'home-articles-content-list'}>
                            {recentArticles.map(article => (
                                !article.thumbnailImgUrl?
                                    <ArticleBoxText key={article.id} link={`/article/${article.urlSlug}`} title={article.title} content={article.contentPreview} category={article.category.categoryName} visibility={returnVisibility(article.accessControlType)}/>
                                    :
                                    <ArticleBox key={article.id} link={`/article/${article.urlSlug}`} title={article.title} category={article.category.categoryName} img={article.thumbnailImgUrl} visibility={returnVisibility(article.accessControlType)}/>
                            ))}
                        </div>
                    </div>
                    {/*<div className={'home-articles-more'}>*/}
                    {/*    <div className={'home-articles-more-text text'}>more</div>*/}
                    {/*    <Icon icon={'chevron-right'} color={'#AFAFAF'} className={'home-articles-more-icon'}/>*/}
                    {/*</div>*/}
                </div>
                :null
            }
            {/*Categories*/}
            {categoryArticles.length>0?
                categoryArticles.map((category) => (
                    <div className={'articleList-category'} key={category.categoryId}>
                        <div className={'articleList-section-title'}>
                            <div style={{display:'flex', flexDirection:'row'}}>
                                <div className={'home-section-title-strip'}>
                                    <div className={'home-section-title-red-strip'}/>
                                </div>
                                <div className={'home-section-title-text text-bold'}>{category.categoryName.toUpperCase()}</div>
                            </div>
                            <div className={'articleList-articles-more'}>
                                <Link to={`/activities/${category.categoryName}`} className={'home-articles-more-text text'}>more</Link>
                                <Icon icon={'chevron-right'} color={'#AFAFAF'} className={'home-articles-more-icon'}/>
                            </div>
                        </div>
                        <div className={'home-promotions-content pure-g'}>
                            {category.articles.length>0?
                                category.articles.map(article => (
                                    windowWidth>768?
                                        <div className={'pure-u-7-24'} key={article.id}>
                                            <div className={'home-promotions-content-card'}>
                                                <ImageBox link={`/article/${article.urlSlug}`} styles={{width: '100%', height: '36.3vh'}} src={article.thumbnailImgUrl?article.thumbnailImgUrl:placeholder}/>
                                                <div className={'home-promotions-content-card-text'}>
                                                    <Link to={`/article/${article.urlSlug}`} className={'home-promotions-content-card-title text'}>{article.title}</Link>
                                                    <div className={'home-promotions-content-card-desc text'}>{article.contentPreview}</div>
                                                    <div className={'home-promotions-content-card-desc text'}><span>{returnVisibility(article.accessControlType)}</span></div>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        !article.thumbnailImgUrl?
                                            <ArticleBoxText key={article.id} link={`/article/${article.urlSlug}`} title={article.title} content={article.contentPreview} category={article.category.categoryName}/>
                                            :
                                            <ArticleBox key={article.id} link={`/article/${article.urlSlug}`} title={article.title} category={article.category.categoryName} img={article.thumbnailImgUrl}/>
                                ))
                                :null
                            }
                        </div>
                    </div>
                ))
                :null
            }
        </div>
    );
}

export default ArticleList;