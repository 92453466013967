import React, {useEffect, useState} from "react";
import '../pages.css'
import {Loader} from '../../components'
import {getService} from '../../service/RestService';
import {URL_PAGINATE_FILES} from '../../service/url';
import {FontAwesomeIcon as Icon} from "@fortawesome/react-fontawesome";
import {Link} from "react-router-dom";

function Files(){
    //Constructor
    useEffect(() => {
        async function mount(){
            await validateAccess()
        }
        mount();
    }, []);

    //State
    const [eligible, setEligible] = useState('Please Wait ...');
    const [files, setFiles] = useState([]);
    const [page, setPage] = useState({});
    const [currentPage, setCurrentPage] = useState(1);

    //Function
    const validateAccess = async() => {
        const result = await getService(URL_PAGINATE_FILES(1,5));
        setEligible(result.resultStatus);
        if(result.resultStatus.resultCode === 'SUCCESS') {
            setFiles(result.page.content);
            setPage(result.page);
        }
    };
    const handleChangePage = async(direction) => {
        let pageChange;
        if(direction === 'next') pageChange = currentPage + 1;
        else pageChange = currentPage - 1;

        const result = await getService(URL_PAGINATE_FILES(pageChange,5));
        setFiles(result.page.content);
        setPage(result.page);
        setCurrentPage(pageChange);
    };

    const windowWidth = window.innerWidth;
    return(
        <div className={'content'}>
            {!files.length>0?
                <div>
                    <div className={'premium-gate'}>
                        <div className={'premium-loader'}><Loader/></div>
                        {eligible.resultCode}
                        {eligible.resultCode==='FAILED'?
                            <div>
                                <div className={'text'} style={{textAlign:'center'}}>{eligible.message}</div>
                                <br/>
                                <Link to={'/account'} className={'premium-back text'} >
                                    <Icon icon={'caret-left'}/>
                                    <div style={{marginLeft:'1vh'}}>Go back to Account</div>
                                </Link>
                            </div>
                            :
                            null
                        }

                    </div>
                </div>
                :
                <div className={'premium-wrapper'}>
                {/* PREMIUM FILES */}
                    <div className={'premium-title text-bold'}>PREMIUM FILES</div>
                    <div className={'premium-table'}>
                        <div className={'premium-table-head pure-g'}>
                            <div className={windowWidth>768?'pure-u-5-8':'pure-u-1-2'}>
                                <div className={'text-bold-minor'} style={{letterSpacing:'0.1vw'}}>NAME</div>
                            </div>
                            <div className={'pure-u-1-4'}>
                                <div className={'text-bold-minor'} style={{letterSpacing:'0.1vw'}}>TYPE</div>
                            </div>
                            <div className={windowWidth>768?'pure-u-1-8':'pure-u-1-4'}>
                                <div className={'text-bold-minor'} style={{letterSpacing:'0.1vw', textAlign:'right'}}>DOWNLOAD</div>
                            </div>
                        </div>
                        <div className={'premium-table-content'}>
                            {/*ROW*/}
                            {files.map(file => (
                                <div className={'premium-table-row pure-g'} key={file.id}>
                                    <div className={windowWidth>768?'pure-u-5-8':'pure-u-1-2'}>
                                        <div className={'premium-table-name text'}>{file.fileName}</div>
                                    </div>
                                    <div className={'pure-u-1-4'}>
                                        <div className={'premium-table-type text'}>{file.fileExt}</div>
                                    </div>
                                    <div className={windowWidth>768?'pure-u-1-8':'pure-u-1-4'}>
                                        <a href={file.fileUrl} className={'premium-table-down text'}><Icon icon={'file-download'}/>{windowWidth>768?<span className={'text'}>Download</span>:null}</a>
                                    </div>
                                </div>
                            ))}
                        </div>
                        {page.totalPages>1?
                            <div className={'premium-table-footer'}>
                                <div className={'premium-table-footer-page text-minor'}>Page ( {currentPage}/{page.totalPages} )</div>
                                <div className={'premium-table-footer-icons'}>
                                    {page.first? null : <Icon icon={'chevron-circle-left'} className={'premium-footer-icon'} onClick={()=>{handleChangePage('prev')}}/>}
                                    {page.last? null : <Icon icon={'chevron-circle-right'} className={'premium-footer-icon'} onClick={()=>{handleChangePage('next')}}/>}
                                </div>
                            </div>
                            :null
                        }
                    </div>
                </div>
            }
        </div>
    );
}

export default Files;