import React, {useState, useEffect} from "react";
import '../../pages.css';
import {URL_GET_BOD} from '../../../service/url'
import {getService} from '../../../service/RestService'
import {FontAwesomeIcon as Icon} from "@fortawesome/react-fontawesome";


function BoardOfDirectors(){
    //States
    const [directors, setDirectors] = useState();

    //Constructor
    useEffect(()=>{
        async function mount(){
            await fetchDirectors();
        }
        mount();
    },[]);

    //Functions
    const fetchDirectors = async() => {
      const result = await getService(URL_GET_BOD);
      setDirectors(result.members);
    };

    const windowWidth = window.innerWidth;
    return(
        directors?
            <div className={'bod-wrapper'}>
                <div className={'bod-section-title text-bold'}>FOUNDERS</div>
                <br/>
                <div className={'bod-row pure-g'}>
                    {directors.map(director => (
                        director.sdiTitle==='Founder'?
                            <div className={windowWidth>768?'pure-u-8-24':'pure-u-1'}>
                                <div className={'bod-person'}>
                                    <img src={director.profileImgUrl} alt="" className={'bod-person-image'}/>
                                    <div className={'bod-person-name text'}>{director.name}</div>
                                    <div className={'bod-person-occupation text'}>{director.sdiTitle}</div>
                                    <div className={'bod-person-company text'}>{director.originCompanyTitle}</div>
                                    <div className={'bod-person-company text'}>{director.originCompany}</div>
                                </div>
                            </div>
                            :
                            null
                    ))}
                </div>
                <div className={'bod-section-title text-bold'}>PENGURUS</div>
                <div className={'bod-row pure-g'}>
                    {directors.map(director => (
                        director.sdiTitle!=='Founder'?
                            <div className={windowWidth>768?'pure-u-8-24':'pure-u-1'}>
                                <div className={'bod-person'}>
                                    <img src={director.profileImgUrl} alt="" className={'bod-person-image'}/>
                                    <div className={'bod-person-name text'}>{director.name}</div>
                                    <div className={'bod-person-occupation text'}>{director.sdiTitle}</div>
                                    <div className={'bod-person-company text'}>{director.originCompanyTitle}</div>
                                    <div className={'bod-person-company text'}>{director.originCompany}</div>
                                </div>
                            </div>
                            :
                            null
                    ))}
                </div>
            </div>
            :
            null

    );
}

export default BoardOfDirectors;