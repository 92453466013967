import React, {useEffect, useState} from "react";
import '../pages.css'
import {RoundedButton, Loader} from '../../components'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import {handleLogin, postForgetPassword} from '../../service/AuthService'
import Cookies from 'js-cookie';

function Login({history}){
    //States
    const [email, setEmail] = useState(null);
    const [password, setPassword] = useState(null);
    const [loginError, setLoginError] = useState(null);
    const [isSignedIn] = useState(Cookies.get('isSignedIn'));
    const [showForgetPass, setShowForgetPass] = useState(false);
    const [forgetEmail, setForgetEmail] = useState(null);
    const [forgetError, setForgetError] = useState(null);
    const [loading, setLoading] = useState(false);

    //Constructor
    useEffect(()=>{
        if(isSignedIn==='true') history.push('/account');
    },[]);

    //Functions
    const login = async() => {
        let body = {
            email: email,
            password: password
        };
        const result = await handleLogin(body);
        let resultStatus = result.resultStatus;
        if(resultStatus.resultCode==='SUCCESS') history.push('/');
        else setLoginError(resultStatus.message);
    };

    const handleForgetPass = async() =>{
        setLoading(true);
        let body = {
            email: forgetEmail
        };
        const result = await postForgetPassword(body);
        let resultStatus = result.resultStatus;
        setLoading(false);
        if(resultStatus.resultCode==='SUCCESS') setForgetError('Recovery email sent, please check your email');
        else setForgetError(resultStatus.message);
    };

    const toggleForgetPass = () => {
        let change = (showForgetPass !== true);
        setShowForgetPass(change);
    };
    return(
        <div className={'content'}>
            {showForgetPass?
                <div>
                    <div className={'login-wrapper'}>
                        <div className={'login-box'}>
                            <div className={'forget-header'}>
                                <Icon icon={'angle-left'} className={'forget-back-icon'}/>
                                <div className={'text forget-back'} onClick={()=>{toggleForgetPass()}}>Back</div>
                                <div className={'text-bold forget-title'}>Forget Password</div>
                            </div>
                            <div className={'forget-content'}>
                                <div className={'login-field-label text-bold'}>Enter your email</div>
                                <div className={'login-field-input'}>
                                    <input type="text" onChange={(event)=>{setForgetEmail(event.target.value)}}/>
                                </div>
                            </div>
                            <div className={'login-error text'}>
                                {loading?<Loader/>:null}
                                {forgetError}
                            </div>
                            <div className={'login-submit'}>
                                <RoundedButton content={'SEND EMAIL'} color={'red'} onClick={()=>handleForgetPass()}/>
                            </div>
                        </div>
                    </div>
                </div>
                :
                <div className={'login-wrapper'}>
                    <div className={'login-box'}>
                        <div className={'login-field'}>
                            <div className={'login-field-label text-bold'}>EMAIL</div>
                            <div className={'login-field-input'}>
                                <input type="text" onChange={(event)=>{setEmail(event.target.value)}}/>
                            </div>
                        </div>
                        <div className={'login-field'}>
                            <div className={'login-field-label text-bold'}>PASSWORD</div>
                            <div className={'login-field-input'}>
                                <input type="password" onChange={(event)=>{setPassword(event.target.value)}}/>
                            </div>
                        </div>
                        <div className={'login-forgetPass text-minor'}>Forgot your password? <br/><span className={'text-bold-minor'} onClick={()=>{toggleForgetPass()}}>Click Here</span></div>
                        <div className={'login-error text'}>
                            {loginError}
                        </div>
                        <div className={'login-submit'}>
                            <RoundedButton content={'SIGN IN'} onClick={()=>{login()}} color={'red'}/>
                        </div>
                    </div>
                    <div className={'login-contact'}>
                        <div className={'text-minor'}>Not a member yet? Contact us to join at</div>
                        <div className={'login-contact-email text-bold-minor'}>info@salesdirector.id</div>
                    </div>
                    {/*<div className={'login-testing'}>*/}
                    {/*    <div className={'text-minor'}>For Testing Purposes</div> <br/>*/}
                    {/*    <div className={'text-bold-minor'}>Admin</div>*/}
                    {/*    <div className={'text-minor'}>email:  admin@salesdirector.id</div>*/}
                    {/*    <div className={'text-minor'}>password:  salesdirectorid</div> <br/>*/}
                    {/*    <div className={'text-bold-minor'}>Member</div>*/}
                    {/*    <div className={'text-minor'}>email:  member@member.com</div>*/}
                    {/*    <div className={'text-minor'}>password:  membertest</div>*/}
                    {/*</div>*/}
                </div>
            }
        </div>
    );
}

export default Login;