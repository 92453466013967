import React from "react";
import '../../pages.css';
import icon1 from '../../../assets/images/icon-1.png';
import icon2 from '../../../assets/images/icon-2.png';
import icon3 from '../../../assets/images/icon-3.png';

function WhyChooseSDI(){
    return(
        <div className={'wcs-wrapper'}>
            <div className={'wcs-title text'}>Why <span className={'text-bold'}>Choose SDI ?</span></div>
            <div className={'wcs-title-border'}>
                <div className={'wcs-title-border-red'}/>
            </div>
            <div className={'wcs-content-title text'}>Associated Member</div>
            <div className={'wcs-content-p text'}>{text1}</div>

            <img src={icon1} alt="" className={'wcs-content-img'}/>
            <div className={'wcs-content-p-title text'}>BUSINESS BUILDING AND COLLABORATION OPPORTUNITIES</div>
            <div className={'wcs-content-p text'}>{text2}</div>

            <img src={icon2} alt="" className={'wcs-content-img'}/>
            <div className={'wcs-content-p-title text'}>NETWORKING AND COLLABORATION FOCUSED EVENTS</div>
            <div className={'wcs-content-p text'}>{text3}</div>
            <div className={'wcs-content-p-list text'}>{text3a}</div>

            <img src={icon3} alt="" className={'wcs-content-img'}/>
            <div className={'wcs-content-p-title text'}>NETWORKING AND EDUCATION EVENTS</div>
            <div className={'wcs-content-p text'}>{text4}</div>

            <img src={icon3} alt="" className={'wcs-content-img'}/>
            <div className={'wcs-content-p-title text'}>SDI COMMUNITIES</div>
            <div className={'wcs-content-p text'}>{text5}</div>


        </div>
    );
}

export default WhyChooseSDI;
const text1 = 'The SDI Associate Member Program offers several benefits such as access, exposure and opportunities to interact and collaborate with other Sales Directors from various industries.\n' +
    'Members will also enjoy exclusive opportunities to reach decision makers and business partners.\n' +
    '\n';
const text2 = 'Through SDI\'s membership, each member has equal and ample opportunities to reach one another and initiate cooperation and collaboration. Through sharing session, all members will learn from one another on the best practices, best strategies and best policies for each industry.';
const text3 = 'SDI will focus on the imperatives of keeping ahead of the accelerating pace of change in all industries, real and services. The catalysts for change represent intense competition, drive for operational excellence, including speed to market, and consumer transparency and customization—all of which are enabled by technology. Because SDI is open to all, the possibility is endless. An exhibit or sponsorship at SDI puts your company name in front of the highest quality audience in the market.\n' +
    '\n';
const text3a = '✔ 90% of attendees at SDI Connect are top management personnel.\n' +
    '✔ 84% have final say, specify suppliers or recommend purchases.\n' +
    '✔ 1/3 are operators with more than 1,000 stores. ';
const text4 = 'SDI hosts a variety of education and networking events that you can participate as a sponsor, exhibitor or attendee.';
const text5 = 'Experts in topics important to sales are available at SDI to keep you informed about emerging issues and build communities to facilitate interaction, learning and idea sharing.';