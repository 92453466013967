import React from "react";
import './loader.css';

const Loader = ({img, title, category, link}) => (
    <div className={'loader-wrapper'}>
        <div className="lds-roller">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    </div>
);

export default Loader;