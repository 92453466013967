import React from "react";
import './articleBox.css';
import {ImageBox} from '../ImageBox';
import {Link} from 'react-router-dom';

const windowWidth = window.innerWidth;
const ArticleBox = ({img, title, category, link, visibility='Public'}) => (
  <Link to={link} className={'articleBox-wrapper'}>
      <div>
          <ImageBox src={img} styles={windowWidth>768?{width: '16.5vw', height: '16.5vw'}:{width: '20vh', height: '20vh'}} shadow={false} link={link}/>
      </div>
      <div className={'articleBox-text'}>
          <div className={'articleBox-text-title text'}>
            {title}
          </div>
          <div className={'articleBox-text-category text'}>
            #{category}
          </div>
          <div className={'articleBoxText-visibility text'}>{visibility}</div>
      </div>
  </Link>
);

export default ArticleBox;