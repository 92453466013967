import React, {useEffect, useState} from "react";
import '../pages.css'
import {RoundedButton} from '../../components'
import {handleLogout} from '../../service/AuthService'
import Cookies from 'js-cookie';
import {Link} from 'react-router-dom';
import {FontAwesomeIcon as Icon} from "@fortawesome/react-fontawesome";
import {postService} from "../../service/RestService";
import {URL_CHANGE_PASSWORD} from '../../service/url'

function Account(props){
    //Constructor
    useEffect(()=>{
        if(Cookies.get('isSignedIn')==='false') props.history.push('/login');
    },[]);

    //State
    const [showChangePass, setShowChangePass] = useState(false);
    const [error, setError] = useState(null);
    const [oldPass, setOldPass] = useState(null);
    const [newPass, setNewPass] = useState(null);
    const [newPass2, setNewPass2] = useState(null);

    const [email] = useState(Cookies.get('email'));
    const [username] = useState(Cookies.get('username'));

    //Functions
    const logout = async () => {
        await handleLogout();
        props.history.push('/');
    };
    const handleChangePassword = async() => {
        if(newPass!==newPass2){setError('New Password does not match')}
        else{
            let body={
                oldPassword: oldPass,
                newPassword: newPass
            };
            const result = await postService(URL_CHANGE_PASSWORD,body);
            if (result.resultStatus.resultCode === 'SUCCESS'){ setError(result.resultStatus.message); await logout(); }
            else setError(result.resultStatus.message);
        }
    };

    const windowWidth = window.innerWidth;

    return(
        <div className={'content'}>
            {!showChangePass?
                //Account Page
                <div className={'account-wrapper'}>
                    <div className={'account-title text-bold'}>MY ACCOUNT</div>
                    <div className={'account-options pure-g'}>
                        <div className={windowWidth>768?'pure-u-1-4':'pure-u-1'}>
                            <div className={'account-options-box'}>
                                <div>
                                    <div className={'account-options-title text-bold'}>Username</div>
                                    <div className={'account-options-title text'} style={{marginBottom:'1vh'}}>{username}</div>
                                    <div className={'account-options-title text-bold'}>Email</div>
                                    <div className={'account-options-title text'}>{email}</div>
                                </div>
                            </div>
                        </div>
                        <div className={windowWidth>768?'pure-u-1-4':'pure-u-1'}>
                            <Link to={'/premium'} className={'account-options-box'}>
                                <Icon icon={'folder-open'} className={'account-options-icon'} color={'#3A3A3A'}/>
                                <div className={'account-options-title text-bold'}>SDI Premium Content</div>
                            </Link>
                        </div>
                        <div className={windowWidth>768?'pure-u-1-4':'pure-u-1'}>
                            <div className={'account-options-box'} onClick={()=>{setShowChangePass(true)}}>
                                <Icon icon={'key'} className={'account-options-icon'} color={'#3A3A3A'}/>
                                <div className={'account-options-title text-bold'}>Change Password</div>
                            </div>
                        </div>
                        <div className={windowWidth>768?'pure-u-1-4':'pure-u-1'}>
                            <div className={'account-options-box sign-out'} onClick={()=>{logout()}}>
                                <Icon icon={'sign-out-alt'} className={'account-options-icon'} color={'white'}/>
                                <div className={'account-options-title text-bold'} style={{color:'white'}}>Sign Out</div>
                            </div>
                        </div>
                    </div>
                </div>
                :
                //Change Password Page
                <div className={'account-wrapper'}>
                    <div className={'login-box'}>
                        <div className={'forget-header'}>
                            <Icon icon={'angle-left'} className={'forget-back-icon'} onClick={()=>{setShowChangePass(false)}}/>
                            <div className={'text forget-back'} onClick={()=>{setShowChangePass(false)}}>Back</div>
                            <div className={'text-bold forget-title'}>Change Password</div>
                        </div>
                        <div className={'forget-content'}>
                            <div className={'login-field-label text-bold'}>Please fill the following</div>
                            <div className={'login-field-input'}>
                                <input type="password" placeholder={'Confirm Old Password'} onChange={(e)=>{setOldPass(e.target.value)}}/>
                            </div>
                            <div className={'login-field-input'}>
                                <input type="password" placeholder={'New Password'} onChange={(e)=>{setNewPass(e.target.value)}}/>
                            </div>
                            <div className={'login-field-input'}>
                                <input type="password" placeholder={'Confirm New Password'} onChange={(e)=>{setNewPass2(e.target.value)}}/>
                            </div>
                        </div>
                        <div className={'login-error text'}>
                            {error}
                        </div>
                        <div className={'login-submit'}>
                            <RoundedButton content={'CHANGE PASSWORD'} color={'red'} onClick={()=>{handleChangePassword()}}/>
                        </div>
                    </div>
                </div>
            }
        </div>
    );
}

export default Account;