import React from "react";
import './imageBox.css';


const ImageBox = ({styles, shadow, src, link}) => (
    <div>
        <a target="_blank" rel="noopener noreferrer" href={link}>
            <div style={shadow?{boxShadow: '0 5px 50px rgba(0, 0, 0, 0.2)'}:{boxShadow:'none'}} className={'imageBox-wrapper'}>
                <img style={styles} src={src} alt={''} className={'pure-img imageBox-img'}/>
            </div>
        </a>
    </div>
);

export default ImageBox;