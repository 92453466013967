import React from "react";
import './articleBox.css';
import {Link} from 'react-router-dom';

const windowWidth = window.innerWidth;
const ArticleBoxText = ({content, title, category, link, visibility='Public'}) => (
    <Link to={link} className={'articleBox-wrapper'}>
        <div  style={{textDecoration:'none'}}>
            {windowWidth>768?
                <div className={'articleBoxText-text'}>
                    <div className={'articleBox-text-title text'}>
                        {title}
                    </div>
                    <div className={'articleBox-text-category text'}>
                        #{category}
                    </div>
                    <div className={'articleBoxText-article text'}>
                        {content}
                    </div>
                    <div className={'articleBoxText-visibility text'}>{visibility}</div>
                </div>
            :
                <div className={'articleBoxText-text'}>
                    <div className={'articleBoxText-head-mobile'}>
                        <div className={'articleBox-text-title text'}>
                            {title}
                        </div>
                        <div className={'articleBox-text-category text'}>
                            #{category}
                        </div>
                    </div>
                    <div className={'articleBoxText-article text'}>
                        {content}
                    </div>
                    <div className={'articleBoxText-visibility text'} style={{marginTop:'1vh'}}>{visibility}</div>
                </div>
            }
        </div>
    </Link>
);

export default ArticleBoxText;