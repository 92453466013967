import React, {useEffect, useState} from "react";
import '../pages.css'
import {ArticleBox, ArticleBoxText} from '../../components';
import {getService} from '../../service/RestService'
import {URL_ALL_CATEGORY, URL_PAGINATE_ARTICLE_CATEGORY} from '../../service/url'
import {Link} from "react-router-dom";

function CategoryList({match, history}){
    //Constructor
    useEffect(()=>{
        async function mount(){
            await validateSlug();
        }
        mount();
    },[match]);

    //State
    const [category, setCategory] = useState();
    const [page, setPage] = useState('1');
    const [totalPage, setTotalPage] = useState(1);
    const [articles, setArticles] = useState([]);

    //Function
    const validateSlug = async() => {
        const slugCategory = await validateSlugCategory(match.params.category);
        if(slugCategory){
            await fetchArticles(slugCategory)
        }else{ history.push('/activities') }
    };
    const validateSlugCategory = async(categoryName) => {
        let id = false;
        const result = await getService(URL_ALL_CATEGORY);
        const categoryList = result.categories;
        for (const category of categoryList){
            if(category.categoryName.toUpperCase() === categoryName.toUpperCase()) {
                id = category.id;
                setCategory(category)
            }
        }
        return id;
    };
    const fetchArticles = async(id) => {
        const result = await getService(URL_PAGINATE_ARTICLE_CATEGORY(page, 8, id));
        setTotalPage(result.page.totalPages);
        setArticles(result.page.content);
    };
    const renderPaginate = (currentPage) => {
        let pagesNumber = [];
        for (let i=1; i<=totalPage; i++){
            pagesNumber.push(i);
        }
        return(
            pagesNumber.map(number=>(
                <div
                    onClick={()=>{handlePageChange(number)}}
                    className={'categoryList-paginate-number text'}>{number}</div>
            ))
        )
    };
    const handlePageChange = async(number) =>{
        const result = await getService(URL_PAGINATE_ARTICLE_CATEGORY(number, 8, category.id));
        setArticles(result.page.content);
        setPage(number)
    };
    const returnVisibility = (visibility) => {
        if(visibility === 'public') return 'Public';
        else if(visibility === 'free_member') return 'Member Only';
        else if(visibility === 'paid_member') return 'Premium Members';
    };

    const windowWidth = window.innerWidth;
    return(
        <div className={'content'}>
            {category? <div className={'categoryList-title text-bold'}>{category.categoryName.toUpperCase()}</div> :null}
            <div className={'wcs-title-border'}>
                <div className={'wcs-title-border-red'}/>
            </div>
            <div className={'categoryList-articles'}>
                <div className={'home-articles-content'}>
                    <div className={'categoryList-articles-content pure-g'}>
                        {articles.length>0?
                            articles.map(article => (
                                    <div className={windowWidth>768?'pure-u-1-4':'pure-u-1'} key={article.id}>
                                        <div className={'categoryList-articleBox'} onClick={()=>{history.push(`/article/${article.urlSlug}`)}}>
                                            {
                                                !article.thumbnailImgUrl ?
                                                    <ArticleBoxText link={`/article/${article.urlSlug}`}
                                                                    title={article.title} content={article.contentPreview}
                                                                    category={article.category.categoryName}
                                                                    visibility={returnVisibility(article.accessControlType)}/>
                                                    :
                                                    <ArticleBox link={`/article/${article.urlSlug}`} title={article.title}
                                                                category={article.category.categoryName}
                                                                img={article.thumbnailImgUrl}
                                                                visibility={returnVisibility(article.accessControlType)}
                                                                />
                                            }
                                        </div>
                                    </div>
                            ))
                            :null
                        }
                    </div>
                </div>
            </div>
            <div className={'categoryList-paginate'}>
                {totalPage>1? renderPaginate(page) : null}
            </div>
        </div>
    );
}

export default CategoryList;