import React, {Component} from "react";
import '../pages.css'
import {Link} from "react-router-dom";

class NotFound extends Component{
    render() {
        return(
            <div className={'content'}>
               <div className={'nf-wrapper'}>
                   <div className={'nf-title text-bold'}>404</div>
                   <div className={'nf-subtitle text-bold'}>Not Found</div>
                   <Link className={'nf-back text-bold'} to={'/'}>BACK TO HOME</Link>
               </div>
            </div>
        );
    }
}

export default NotFound;