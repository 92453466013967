import React from "react";
import '../pages.css'
import {FontAwesomeIcon as Icon} from "@fortawesome/react-fontawesome";
import {Link} from "react-router-dom";
import {RoundedButton} from '../../components'

function Premium (){

    return(
        <div className={'content'}>
            <div>
                <div className={'text-bold premium-gate-title'}>SDI Premium Content</div>
                <div className={'premium-gate-buttons'}>
                    <RoundedButton content={'Premium Files'} link={'/premium/files'}/>
                    <RoundedButton content={'Member Database'} link={'/premium/members'}/>
                </div>
            </div>
        </div>
    )
}

export default Premium;