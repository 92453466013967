import React from 'react';
import './footer.css';
import logo from '../../../assets/images/Logo SDI.png';
import logoBF from '../../../assets/images/logo bf.png';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import {Link} from 'react-router-dom'
import instagram from '../../../assets/images/instagram.png'

const windowWidth = window.innerWidth;

const Footer = () => (
    <div className={'footer-wrapper'}>
        {windowWidth>768?
            <div className={'pure-g footer-content'}>
                <div className={'pure-u-7-24'}>
                    <div className={'footer-contactUs'}>
                        <div className={'footer-content-title'}>
                            Contact Us
                        </div>
                        <div className={'footer-content-list pure-g'}>
                            <div className={'pure-u-1-12'}>
                                <Icon icon={'map-marker-alt'} color={'#EB292F'} className={'footer-icon'}/>
                            </div>
                            <div className={'pure-u-11-12'}>
                                <div className={'footer-contactUs-list-title text'}>BEEZY Work</div>
                                <div className={'footer-contactUs-address text'}>
                                    Ruko Permata Regency Blok C No.31
                                    Jl. H. Kelik, Srengseng, Kembangan
                                    Jakarta Barat 11630
                                </div>
                            </div>
                        </div>
                        <div className={'footer-content-list pure-g'}>
                            <div className={'pure-u-1-12'}>
                                <Icon icon={'phone'} color={'#EB292F'} className={'footer-icon'}/>
                            </div>
                            <div className={'pure-u-11-12'}>
                                <a href={'https://wa.me/082285893232'} className={'footer-contactUs-list-title'}><span>0822-8589-3232</span></a>
                            </div>
                        </div>
                        <div className={'footer-content-list pure-g'}>
                            <div className={'pure-u-1-12'}>
                                <Icon icon={'envelope'} color={'#EB292F'} className={'footer-icon'}/>
                            </div>
                            <div className={'pure-u-11-12'}>
                                <div className={'footer-contactUs-list-title'}>info@salesdirector.id</div>
                            </div>
                        </div>
                        <div className={'footer-content-list pure-g'}>
                            <div className={'footer-icon pure-u-1-12'}>
                                <img src={instagram} className={''} alt=""/>
                            </div>
                            <div className={'pure-u-11-12'}>
                                <a href={'https://www.instagram.com/salesdirectorid/'} className={'footer-contactUs-list-title'}><span>@salesdirectorid</span></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={'pure-u-7-24'}>
                    <div className={'footer-getAround'}>
                        <div className={'footer-content-title'}>
                            Get Around The Site
                        </div>
                        <div className={'footer-getAround-list'}>
                            <Link to={'/'} className={'footer-getAround-title text'}>Home</Link>
                            <Link to={'/about'} className={'footer-getAround-title text'}>About Us</Link>
                            <Link to={'/activities'} className={'footer-getAround-title text'}>Activities</Link>
                            <Link to={'/privacy'} className={'footer-getAround-title text'}>Privacy Policy</Link>
                        </div>
                    </div>
                </div>
                <div className={'pure-u-1-4'}>
                    <div className={'footer-sdi'}>
                        <div className={'footer-sdi-logo'}>
                            <img src={logo} className='pure-img' alt='logo'/>
                        </div>
                        <div className={'footer-sdi-text'}>Sales Director Indonesia 2020</div>
                        <div className={'footer-bf-logo'}>
                            <img src={logoBF} className='pure-img' alt='logo'/>
                        </div>
                        <div className={'footer-bf'}>this site is powered by <a href="https://byteforce.id/" className={'footer-bf-link'}>Byteforce Indonesia</a></div>
                    </div>
                </div>
            </div>
            :
            <div className={'footer-mobile-wrapper'}>
                <div className={'footer-mobile-section'}>
                    <div className={'footer-content-title'}>
                        Contact Us
                    </div>
                    <div className={'footer-content-list pure-g'}>
                        <div className={'pure-u-1-12'}>
                            <Icon icon={'map-marker-alt'} color={'#EB292F'} className={'footer-icon'}/>
                        </div>
                        <div className={'pure-u-11-12'}>
                            <div className={'footer-contactUs-list-title text'}>BEEZY Work</div>
                            <div className={'footer-contactUs-address text'}>
                                Ruko Permata Regency Blok C No.31
                                Jl. H. Kelik, Srengseng, Kembangan
                                Jakarta Barat 11630
                            </div>
                        </div>
                    </div>
                    <div className={'footer-content-list pure-g'}>
                        <div className={'pure-u-1-12'}>
                            <Icon icon={'phone'} color={'#EB292F'} className={'footer-icon'}/>
                        </div>
                        <div className={'pure-u-11-12'}>
                            <a href={'https://wa.me/082285893232'} className={'footer-contactUs-list-title'}><span>0822-8589-3232</span></a>
                        </div>
                    </div>
                    <div className={'footer-content-list pure-g'}>
                        <div className={'pure-u-1-12'}>
                            <Icon icon={'envelope'} color={'#EB292F'} className={'footer-icon'}/>
                        </div>
                        <div className={'pure-u-11-12'}>
                            <div className={'footer-contactUs-list-title'}>info@salesdirector.id</div>
                        </div>
                    </div>
                    <div className={'footer-content-list pure-g'}>
                        <div className={'footer-icon pure-u-1-12'}>
                            <img src={instagram} className={''} alt=""/>
                        </div>
                        <div className={'pure-u-11-12'}>
                            <a href={'https://www.instagram.com/salesdirectorid/'} className={'footer-contactUs-list-title'}>@salesdirectorid</a>
                        </div>
                    </div>
                </div>
                <div className={'footer-mobile-section'}>
                    <div className={'footer-getAround'}>
                        <div className={'footer-content-title'}>
                            Get Around The Site
                        </div>
                        <div className={'footer-getAround-list'} style={{paddingLeft:'5vw'}}>
                            <Link to={'/'} className={'footer-getAround-title text'}>Home</Link>
                            <Link to={'/about'} className={'footer-getAround-title text'}>About Us</Link>
                            <Link to={'/activities'} className={'footer-getAround-title text'}>Activities</Link>
                            <Link to={'/privacy'} className={'footer-getAround-title text'}>Privacy Policy</Link>
                        </div>
                    </div>
                </div>
                <div>
                    <div className={'footer-sdi'}>
                        <div className={'footer-sdi-logo'}>
                            <img src={logo} className='pure-img' alt='logo'/>
                        </div>
                        <div className={'footer-sdi-text'}>Sales Director Indonesia 2020</div>
                        <div className={'footer-bf-logo'}>
                            <img src={logoBF} className='pure-img' alt='logo'/>
                        </div>
                        <div className={'footer-bf'}>this site is powered by <a href="https://byteforce.id/">Byteforce Indonesia</a></div>
                    </div>
                </div>
            </div>
        }
    </div>
);

export default Footer