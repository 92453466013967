import React from "react";
import './button.css';
import {Link} from "react-router-dom";

const RoundedButton = ({content, onClick, color='red', link}) => (
    onClick?
        <div className={'rbtn-wrapper'} onClick={onClick} style={color==='red'?{backgroundColor: '#EB292F'}:{backgroundColor:'white'}}>
            <div className={'rbtn-content text-bold'} style={color==='red'?{color: 'white'}:{color: '#3A3A3A'}}>
                {content}
            </div>
        </div>
        :
        <Link className={'rbtn-wrapper'} to={link} style={color==='red'?{backgroundColor: '#EB292F', display:'block'}:{backgroundColor:'white', display:'block'}}>
            <div className={'rbtn-content text-bold'} style={color==='red'?{color: 'white'}:{color: '#3A3A3A'}}>
                {content}
            </div>
        </Link>
);

export default RoundedButton;