import React, {Component} from "react";
import '../../pages.css';
import obg1 from '../../../assets/images/obg1.jpg';
import obg2 from '../../../assets/images/obg2.png';

class OurBackground extends Component{
    render(){
        return(
            <div className={'obg-wrapper text'}>
                {text1}
                <img src={obg1} alt="" style={{marginTop: '2vh'}}/>
                <img src={obg2} alt="" style={{marginBottom: '2vh'}}/>
                {text2}
            </div>
        );
    }
}

export default OurBackground;
const text1 = 'Imagine there is a new company and the owner only focus on activities such as developing product and system, build warehouse and other activities, but never focus in sales.\n' +
    '\n' +
    'What would happen to this company?\n' +
    '\n' +
    'In a short period, the company will facing some problems because they can’t sell and distribute products they bought. If t hey ignore the problem and not taking serious action, they will go bankrupt.\n' +
    '\n' +
    'Sales determines the life or death of a company.\n' +
    '\n' +
    'The greater the sales results, the greater the company growth.\n' +
    '\n' +
    'There is a presumption that the sales task is only done by the sales force who are in the frontline.\n' +
    '\n' +
    'Formally it is true that sales force is only function to sell, But in the Organization this functions is embedded in the waistband to every sales leader even to the level of Sales Director, CEO and even Owner.\n' +
    '\n' +
    'If every company leaders realize about the important of selling, it will resulting a positive impact on the company\'s sales performance.\n' +
    '\n' +
    'On the other hand, world is changing. Today as a trend towards digital, MEA competition and various other challenges, every leader in the company is expected to be more responsive and nimble.\n' +
    '\n' +
    'Sales Director, CEO, President Director and owner of the company can not only rely on the strength of his or her own company.';
const text2 = 'Collaboration become one of the strategy that essential to enable the company\'s survival and achieving greater success.\n' +
    '\n' +
    'Recognizing the importance of collaboration and improvement for Sales Leader, I\'ve contacted some of the Sales Director, CEO, President Director and owner of the company that I know.\n' +
    '\n' +
    'From these discussions finally arose the idea of making a Sales Director Indonesia (SDI) as a forum for Sales Director, CEO, President Director and owner of the company.\n' +
    '\n' +
    'SDI is expected to facilitate collaboration among the members and help accelerate the rise in sales performance of each member.\n' +
    '\n' +
    'Why is named Sales Director Indonesia?\n' +
    '\n' +
    'Because this community is destined to the Vice President, Sales Director, Director, CEO, President Director and owner of the company.\n' +
    '\n' +
    'Through SDI each member can make a positive contribution to the advancement of sales and economic Indonesia.\n' +
    '\n' +
    'Regards,\n' +
    '\n' +
    'Dedy Budiman\n' +
    'Founder Sales Director Indonesia';