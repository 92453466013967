import React from 'react';
import ReactDOM from 'react-dom';
import 'purecss/build/pure.css';
import {default as Navigation} from './routes'
import * as serviceWorker from './serviceWorker';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import {loginCheck} from './service/AuthService';
import ReactGA from 'react-ga';

loginCheck();
library.add(fas); // fontawesome
ReactGA.initialize('UA-153550735-2'); //Analytics

ReactDOM.render(<Navigation />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
