// API URL
// export const URL_MAIN = "https://api.sdi.dev.ailiv.xyz"; //Development
export const URL_MAIN = process.env.REACT_APP_API; //Production
export const URL_API = URL_MAIN+"/api/v1";
export const URL_PUBLIC = URL_API+'/public';

// Authentication
export const URL_AUTH = URL_API+"/auth";
export const URL_LOGIN = URL_AUTH+"/login";
export const URL_LOGOUT = URL_AUTH+"/logout";
export const URL_VALIDATE_LOGIN = URL_AUTH+"/verify";
export const URL_CHANGE_PASSWORD = URL_AUTH+"/change-password";
export const URL_FORGET_PASSWORD = URL_AUTH+"/forgot-password";
export const URL_VALIDATE_RESET_PASSWORD = (resetToken) => URL_AUTH+`/reset-password/${resetToken}`;
export const URL_RESET_PASSWORD = (resetToken) => URL_AUTH+`/reset-password/${resetToken}`;

// Article
export const URL_ARTICLE = URL_PUBLIC + '/article';
export const URL_ARTICLE_CATEGORY = URL_ARTICLE + '-category';
export const URL_ALL_CATEGORY = URL_ARTICLE_CATEGORY + '/all';
export const URL_PAGINATE_ARTICLE_ALL = (page, number) => URL_ARTICLE + `/paginate?p=${page}&n=${number}`;
export const URL_PAGINATE_ARTICLE_CATEGORY = (page, number, category) => URL_ARTICLE + `/paginate?p=${page}&n=${number}&c=${category}`;
export const URL_ARTICLE_DETAIL = (slug) => URL_API + `/article/${slug}`;

// Company
export const URL_COMPANY = URL_PUBLIC + '/company';
export const URL_PAGINATE_COMPANY_LOGO = (page, number) => URL_COMPANY + `/logos/paginate?p=${page}&n=${number}`;
export const URL_COMPANY_BY_ID = (id) => URL_API + `/company/${id}`;

//Premium Files
export const URL_RESOURCE = URL_API + '/resource';
export const URL_PAGINATE_FILES = (page, number) => URL_RESOURCE + `/files/paginate?p=${page}&n=${number}`;
export const URL_PAGINATE_MEMBERS = (page, number) => URL_RESOURCE + `/members/paginate?p=${page}&n=${number}`;

//Board of Director
export const URL_BOD = URL_PUBLIC + '/board-member';
export const URL_GET_BOD = URL_BOD + '/all';