import React, {useEffect, useState} from "react";
import '../pages.css'
import {RoundedButton} from "../../components/Button";
import {getService, postService} from '../../service/RestService';
import {URL_VALIDATE_RESET_PASSWORD, URL_RESET_PASSWORD} from '../../service/url';

function ResetPass({match, history}){
    //CONSTRUCTOR
    useEffect(()=>{
        validateResetToken();
    },[]);

    //STATES
    const [error, setError] = useState(null);
    const [newPass, setNewPass] = useState(null);
    const [newPass2, setNewPass2] = useState(null);

    //FUNCTIONS
    const validateResetToken = async() => {
        const result = await getService(URL_VALIDATE_RESET_PASSWORD(match.params.id));
        if (result.resultStatus.resultCode === 'FAILED') history.push('/404');
    };
    const handleResetPassword = async() => {
        if(newPass!==newPass2){setError('Password does not match')}
        else{
            let body = {
              password: newPass
            };
            const result = await postService(URL_RESET_PASSWORD(match.params.id), body);
            if (result.resultStatus.resultCode === 'SUCCESS') history.push('/login');
            else setError(result.resultStatus.message);
        }

    };

    return(
        <div className={'content'}>
            <div>
                <div className={'login-wrapper'}>
                    <div className={'login-box'}>
                        <div className={'forget-header'}>
                            <div className={'text-bold forget-title'}>Change Password</div>
                        </div>
                        <div className={'forget-content'}>
                            <div className={'login-field-label text-bold'}>Enter your new password</div>
                            <div className={'login-field-input'}>
                                <input type="password" onChange={(e)=>{setNewPass(e.target.value)}} placeholder={'New Password'}/>
                            </div>
                            <div className={'login-field-input'}>
                                <input type="password" onChange={(e)=>{setNewPass2(e.target.value)}} placeholder={'Confirm New Password'}/>
                            </div>
                        </div>
                        <div className={'login-error text'}>
                            {error}
                        </div>
                        <div className={'login-submit'}>
                            <RoundedButton content={'CHANGE PASSWORD'} color={'red'} onClick={()=>{handleResetPassword()}}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ResetPass;