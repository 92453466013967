import React, {useEffect, useState} from 'react';
import '../pages.css'
import {ImageBox, ArticleBox, ArticleBoxText, Modal, RoundedButton} from "../../components";
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import {URL_PAGINATE_ARTICLE_ALL, URL_PAGINATE_ARTICLE_CATEGORY, URL_PAGINATE_COMPANY_LOGO, URL_COMPANY_BY_ID} from '../../service/url';
import {getService} from '../../service/RestService';
import Cookies from 'js-cookie';
import {Link} from 'react-router-dom';
import banner8thAnniversary from '../../assets/images/banner-8th-anniversary.jpg';

//PLACEHOLDERS
import placeholder from '../../assets/images/graphic-placeholder.png';

function Home(){
    //State
    const [carouselArticles, setCarouselArticles] = useState([]);
    const [recentArticles, setRecentArticles] = useState([]);
    const [promotionArticles, setPromotionArticles] = useState([]);

    const [showModalCompany, setShowModalCompany] = useState(false);
    const [companyLogos, setCompanyLogos] = useState([]);
    const [companyId, setCompanyId] = useState(1);
    const [currentCompanyPage, setCurrentCompanyPage] = useState(1);
    const [totalCompanyPages, setTotalCompanyPages] = useState();

    const [isSignedIn,setIsSignedIn] = useState(Cookies.get('isSignedIn'));
    const [currentCarousel, setCurrentCarousel] = useState(0);
    const [carouselImages, setCarouselImages] = useState();

    //Constructor
    useEffect( ()=>{
        async function mount(){
            await fetchArticlesCarousel();
            await fetchArticlesRecent();
            await fetchArticlesPromotion();
        }
        mount();

        // IG Feed EmbedSocial Widget Function
        window.iFrameResize();

        // var iframe = document.getElementById("ig-iframe");

        // document.getElementById("ig-iframe").contentWindow.document.body.onclick = function() {
        //     window.location.href = "https://www.instagram.com/salesdirectorid/";
        // };
        
        // var elmnt = iframe.contentWindow.document.getElementsByClassName("swiper-slide");
        
        // .forEach(a => a.addEventListener("click", e=>{
        //     window.location.href = "https://www.instagram.com/salesdirectorid/";
        //     console.log("found iframe div");
        // }));

        // document.querySelectorAll('div.es-media-card-container').forEach(a => a.addEventListener("click", e=>{
        //     window.location.href = "https://www.instagram.com/salesdirectorid/";
        //     console.log("found iframe div");
        // }));


    },[]);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentCarousel(currentCarousel => (currentCarousel + 1) % 5)
        }, 2200);
        return () => clearInterval(interval);
    }, []);

    //Function
    const fetchArticlesCarousel = async() => {
        // let category = (Math.floor(Math.random() * Math.floor(4)) + 1);
        const result = await getService(URL_PAGINATE_ARTICLE_CATEGORY(1,5, 1));
        setCarouselArticles(await result.page.content);
    };
    const fetchArticlesRecent = async() => {
      const result = await getService(URL_PAGINATE_ARTICLE_ALL(1,10));
      const temp = [];
        result.page.content.map(article => (
            article.category.id !== 2? temp.push(article) : null
        ));
      setRecentArticles(temp.slice(0,4))
    };
    const fetchArticlesPromotion = async() => {
        const result = await getService(URL_PAGINATE_ARTICLE_CATEGORY(1,3, 2));
        setPromotionArticles(await result.page.content);
    };

    const fetchCompanyLogos = async() => {
        const result = await getService(URL_PAGINATE_COMPANY_LOGO(currentCompanyPage, 6));
        setCompanyLogos(result.page.content);
        setTotalCompanyPages(result.page.totalPages);
    };
    const fetchCompanyId = async(id) => {
        const result = await getService(URL_COMPANY_BY_ID(id));
        setCompanyId(result);
    };

    const handleCompanyClick = async(id) =>{
        await fetchCompanyId(id);
        toggleModal();
    };

    const toggleModal = () => {
        let change = (showModalCompany !== true);
        setShowModalCompany(change);
    };

    const handleCarouselArrow = (move) => {
        let length = carouselArticles.length-1;
        let pointer = 0;

        if(move==='next'){
            if(currentCarousel!==length) pointer=currentCarousel+1;
        }else if(move==='prev'){
            if(currentCarousel===0) pointer=length;
            else pointer=(currentCarousel-1);
        }
        setCurrentCarousel(pointer);
    };
    const handleCompanyArrow = async(move) => {
        let totalPages = totalCompanyPages;
        let pointer = 1;

        if(move==='next'){
            if(currentCompanyPage!==totalPages) pointer=currentCompanyPage+1;
        }else if(move==='prev'){
            if(currentCompanyPage===1) pointer=totalPages;
            else pointer=(currentCompanyPage-1);
        }
        setCurrentCompanyPage(pointer);
        const result = await getService(URL_PAGINATE_COMPANY_LOGO(pointer, 6));
        setCompanyLogos(result.page.content);
    };
    const returnVisibility = (visibility) => {
        if(visibility === 'public') return 'Public';
        else if(visibility === 'free_member') return 'Member Only';
        else if(visibility === 'paid_member') return 'Premium Members';
    };

    const redirectToIG = () => {
        window.location.href = "https://www.instagram.com/salesdirectorid/";
    };

    const windowWidth = window.innerWidth;
    return(
        <div className={"content"}>
            {/*Carousel*/}
            {carouselArticles.length>0?
                <div className={'home-carousel'}>
                    <div className={'home-carousel-text'}>
                        <div className={'home-carousel-text-content'}>
                            <Link to={`/article/${carouselArticles[currentCarousel].urlSlug}`} className={'home-carousel-text-content-title text'}>
                                {carouselArticles[currentCarousel].title}
                            </Link>
                            <div className={'home-carousel-text-content-article text'}>
                                {carouselArticles[currentCarousel].contentPreview}
                            </div>
                            <div className={'home-carousel-text-content-category text'}>
                                #{carouselArticles[currentCarousel].category.categoryName}
                            </div>
                        </div>
                    </div>
                    <div className={'home-carousel-image pure-img'}>
                        <div className={'home-carousel-visibility text'}>{returnVisibility(carouselArticles[currentCarousel].accessControlType)}</div>
                        {/*Image Carousel*/}
                        <ImageBox styles={windowWidth>768?{width: '30vw', height: '30vw'}:{width: '100%', height: '43.65vh'}} shadow={true} link={`/article/${carouselArticles[currentCarousel].urlSlug}`} src={carouselArticles[currentCarousel].thumbnailImgUrl?carouselArticles[currentCarousel].thumbnailImgUrl:placeholder}/>
                    </div>
                    <div className={'home-carousel-scroll'}>
                        <Icon icon={'chevron-left'} color={'#3A3A3A'} className={'home-carousel-scroll-arrow'} onClick={()=>{handleCarouselArrow('prev')}}/>
                        <div className={'home-carousel-scroll-bullet-list'}>
                            <Icon icon={'circle'} color={currentCarousel===0?'#3A3A3A':'#D9D9D9'} className={'home-carousel-scroll-bullet'}/>
                            <Icon icon={'circle'} color={currentCarousel===1?'#3A3A3A':'#D9D9D9'} className={'home-carousel-scroll-bullet'}/>
                            <Icon icon={'circle'} color={currentCarousel===2?'#3A3A3A':'#D9D9D9'} className={'home-carousel-scroll-bullet'}/>
                            <Icon icon={'circle'} color={currentCarousel===3?'#3A3A3A':'#D9D9D9'} className={'home-carousel-scroll-bullet'}/>
                            <Icon icon={'circle'} color={currentCarousel===4?'#3A3A3A':'#D9D9D9'} className={'home-carousel-scroll-bullet'}/>
                        </div>
                        <Icon icon={'chevron-right'} color={'#3A3A3A'} className={'home-carousel-scroll-arrow'} onClick={()=>{handleCarouselArrow('next')}}/>
                    </div>
                </div>
                :null
            }

            {/* 8th Anniversary RSVP */}
            <div className={'home-special-event'}>
                <div className={'home-section-title'}>
                    <div className={'home-section-title-strip'}>
                        <div className={'home-section-title-red-strip'}/>
                    </div>
                    <div className={'home-section-title-text text-bold'}>EVENT REGISTRATION</div>
                </div>
                <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                    <div className={'home-special-event-banner'}>
                        <ImageBox styles={{width: '100%'}} 
                            src={banner8thAnniversary} 
                            link={'https://www.synergyhub.id/sales/directorindonesia/anniversary-8th-sdi'}/>
                    </div>
                </div>
            </div>

            {/*Recent Articles*/}
            {recentArticles.length>0?
                <div className={'home-articles'}>
                    <div className={'home-section-title'}>
                        <div className={'home-section-title-strip'}>
                            <div className={'home-section-title-red-strip'}/>
                        </div>
                        <div className={'home-section-title-text text-bold'}>INFORMATION & ARTICLES</div>
                    </div>
                    <div className={'home-articles-content'}>
                        <div className={'home-articles-content-list'}>
                            {recentArticles.map(article => (
                                // <ArticleBox key={article.id} title={article.title} category={article.category.categoryName} img={article.thumbnailImgUrl}/>
                                !article.thumbnailImgUrl?
                                    <ArticleBoxText key={article.id} link={`/article/${article.urlSlug}`} title={article.title} content={article.contentPreview} category={article.category.categoryName} visibility={returnVisibility(article.accessControlType)}/>
                                    :
                                    <ArticleBox key={article.id} link={`/article/${article.urlSlug}`} title={article.title} category={article.category.categoryName} img={article.thumbnailImgUrl} visibility={returnVisibility(article.accessControlType)}/>
                            ))}
                        </div>
                    </div>
                    {/*<div className={'home-articles-more'}>*/}
                    {/*    <div className={'home-articles-more-text text'}>more</div>*/}
                    {/*    <Icon icon={'chevron-right'} color={'#AFAFAF'} className={'home-articles-more-icon'}/>*/}
                    {/*</div>*/}
                </div>
                :null
            }
            {/*Promotions*/}
            {promotionArticles.length>0?
                <div className={'home-promotions'}>
                    <div className={'home-section-title'}>
                        <div className={'home-section-title-strip'}>
                            <div className={'home-section-title-red-strip'}/>
                        </div>
                        <div className={'home-section-title-text text-bold'}>PROMOTIONS</div>
                    </div>
                    {windowWidth>768?
                        <div className={'home-promotions-content pure-g'}>
                            {promotionArticles.map((article, index) => (
                                <div className={'pure-u-7-24'} key={article.id}>
                                    <div className={'home-promotions-content-card'}>
                                        <ImageBox link={`/article/${article.urlSlug}`} styles={{width: '100%', height: '20.4vw'}} src={article.thumbnailImgUrl}/>
                                        <div className={'home-promotions-content-card-text'}>
                                            <Link to={`/article/${article.urlSlug}`} className={'home-promotions-content-card-title text'}>{article.title}</Link>
                                            <div className={'home-promotions-content-card-desc text'}>{article.contentPreview}</div>
                                            <div className={'home-promotions-content-card-desc text'}><span>{returnVisibility(article.accessControlType)}</span></div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    :
                    <div className={'home-promotions-content'}>
                        {promotionArticles.map((article, index) => (
                            <div className={'home-promotions-content-card'} key={article.id}>
                                <ImageBox link={`/article/${article.urlSlug}`} styles={{width: '100%', height: '43.7vh'}} src={article.thumbnailImgUrl}/>
                                <div className={'home-promotions-content-card-text'}>
                                    <Link to={`/article/${article.urlSlug}`} className={'home-promotions-content-card-title text'}>{article.title}</Link>
                                    <div className={'home-promotions-content-card-desc text'}>{article.contentPreview}</div>
                                    <div className={'home-promotions-content-card-desc text'}><span>{returnVisibility(article.accessControlType)}</span></div>
                                </div>
                            </div>
                        ))}
                    </div>}
                </div>
                :null
            }

            {/* Instagram Embed Widget */}
            <div className={'home-ig-feed'}>
                <div className={'home-section-title'}>
                    <div className={'home-section-title-strip'}>
                        <div className={'home-section-title-red-strip'}/>
                    </div>
                    <div className={'home-section-title-text text-bold'}>INSTAGRAM</div>
                </div>
                <iframe id="ig-iframe" style={{border:"0", width:"100%", height: "100%"}} scrolling="no" src="https://embedsocial.com/api/pro_hashtag/163908c28ad8e585f02c72ba0f74ca44cc27434b"></iframe>
            </div>

            {/*Company Profiles*/}
            <div className={'home-members'}>
                <div className={'home-section-title'} style={{justifyContent:'center', alignItems:'center'}}>
                    <div className={'home-section-title-strip'}>
                        <div className={'home-section-title-red-strip'}/>
                    </div>
                    <div className={'home-section-title-text text-bold'}>OUR MEMBERS</div>
                    <div className={'home-section-title-strip'}>
                        <div className={'home-section-title-red-strip'}/>
                    </div>
                </div>
                <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                    <Link to={'/about/members'} className={'home-members-more text'}>Go to Our Members Page</Link>
                </div>
            </div>
            
            
            
        </div>
    );
}

export default Home;
