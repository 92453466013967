import React, {useEffect, useState} from "react";
import '../pages.css';
import {OurBackground, BoardOfDirectors, WhyChooseSDI, OurMember} from './sub'
import logoSdi from '../../assets/images/Logo SDI.png';
import imgPeta from '../../assets/images/peta.png';
import imgVisi from '../../assets/images/visi.png';
import imgMisi from '../../assets/images/misi.png';
import {FontAwesomeIcon as Icon} from "@fortawesome/react-fontawesome";
import {Link} from "react-router-dom";


function AboutUs({match, history}){
    //State
    const [subPage, setSubPage] = useState(1);

    //Constructor
    useEffect(() => {
        validateParams();
    },[match]);

    //Function
    const validateParams = () => {
      let change;
        if(match.params.page){
            switch(match.params.page){
                case 'background':
                    change = 1;
                    break;
                case 'why-sdi':
                    change = 2;
                    break;
                case 'directors':
                    change = 3;
                    break;
                case 'members':
                    change = 4;
                    break;
                default:
                    history.push('/about/background')
            }
            setSubPage(change);
        }
    };
    const renderSubPage = () => {
        if (subPage === 1) return(<OurBackground/>);
        else if (subPage === 2) return(<WhyChooseSDI/>);
        else if (subPage === 3) return(<BoardOfDirectors/>);
        else return(<OurMember/>);
    };

    const windowWidth = window.innerWidth;
    return (
        <div className={'content'}>
            {/*Header*/}
            <div className={'aboutUs-header'}>
                <div className={'aboutUs-header-title text-bold'}>
                    About Us
                </div>
                <div className={'aboutUs-header-content pure-g'}>
                    <div className={windowWidth>600?'aboutUs-header-content-sdi pure-u-1-4':'aboutUs-header-content-sdi pure-u-1'}>
                        <div className={'aboutUs-header-content-logo'}>
                            <img src={logoSdi} alt="placeholder"/>
                        </div>
                        <div className={'aboutUs-header-content-desc'}>
                            <div className={'pure-g'}>
                                <div className={'pure-u-1-12'}>
                                    <Icon icon={'map-marker-alt'} color={'#EB292F'} className={'aboutUs-header-content-sdi-icon'}/>
                                </div>
                                <div className={'pure-u-11-12 aboutUs-header-content-sdi-desc'}>
                                    <div className={'aboutUs-header-content-desc-text text'}>BEEZY Work</div>
                                    <div className={'aboutUs-header-content-desc-text text'}>
                                        Ruko Permata Regency Blok C No.31
                                        Jl. H. Kelik, Srengseng, Kembangan
                                        Jakarta Barat 11630
                                    </div>
                                </div>
                            </div>
                            <div className={'pure-g'} style={{marginTop: '2vh'}}>
                                <div className={'pure-u-1-12'}>
                                    <Icon icon={'envelope'} color={'#EB292F'} className={'aboutUs-header-content-sdi-icon'}/>
                                </div>
                                <div className={'pure-u-11-12 aboutUs-header-content-sdi-desc'}>
                                    <div className={'aboutUs-header-content-desc-text text'}>info@salesdirector.id</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={windowWidth>600?'aboutUs-header-content-vm pure-u-3-4':'aboutUs-header-content-vm pure-u-1'}>
                        <div className={'aboutUs-header-content-vm-box'}>
                            <div className={'aboutUs-header-content-vm-box-img'}>
                                <img src={imgPeta} alt=""/>
                            </div>
                            <div className={'aboutUs-header-content-vm-container'}>
                                <div className={windowWidth>600?'pure-g':null} style={{width: '100%'}}>
                                    <div className={windowWidth>600?'pure-u-1-8 aboutUs-header-content-vm-image':'aboutUs-header-content-vm-image'}>
                                        <img src={imgVisi} alt=""/>
                                        <div className={'aboutUs-header-content-vm-part-title text-bold'}>VISION</div>
                                    </div>
                                    <div className={windowWidth>600?'pure-u-7-8':null}>
                                        <div className={'aboutUs-header-content-vm-text text'}>
                                            We envision an Indonesia where all sales people even in the most remotes areas - hold the power to create opportunity for others.
                                        </div>
                                    </div>
                                </div>
                                {windowWidth>600?
                                    <div className={'pure-g'} style={{width: '100%', marginTop: '2vh'}}>
                                        <div className={'pure-u-7-8'}>
                                            <div className={'aboutUs-header-content-vm-text text'}>
                                                To implement sustainable programs that improve access worldwide to collaboration and business acceleration for future sales generations.
                                            </div>
                                        </div>
                                        <div className={'pure-u-1-8 aboutUs-header-content-vm-image'}>
                                            <img src={imgMisi} alt=""/>
                                            <div className={'aboutUs-header-content-vm-part-title text-bold'}>MISSION</div>
                                        </div>
                                    </div>
                                :
                                    <div style={{width: '100%', marginTop: '2vh'}}>
                                        <div className={'aboutUs-header-content-vm-image'}>
                                            <img src={imgMisi} alt=""/>
                                            <div className={'aboutUs-header-content-vm-part-title text-bold'}>MISSION</div>
                                        </div>
                                        <div>
                                            <div className={'aboutUs-header-content-vm-text text'}>
                                                To implement sustainable programs that improve access worldwide to collaboration and business acceleration for future sales generations.
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*Selector*/}
            <div className={'aboutUs-content-selector'}>
                <div className={'aboutUs-content-selector-row'}>
                    <Link to={'/about/background'} className={subPage===1?'aboutUs-content-selector-current text':'aboutUs-content-selector-other text'}>
                        Our Background
                    </Link>
                    <Link to={'/about/why-sdi'} className={subPage===2?'aboutUs-content-selector-current text':'aboutUs-content-selector-other text'}>
                        Why Choose SDI?
                    </Link>
                    <Link to={'/about/directors'} className={subPage===3?'aboutUs-content-selector-current text':'aboutUs-content-selector-other text'}>
                        Board of Directors
                    </Link>
                    <Link to={'/about/members'} className={subPage===4?'aboutUs-content-selector-current text':'aboutUs-content-selector-other text'}>
                        Our Members
                    </Link>
                </div>
            </div>
            {/*Content*/}
            <div className={'aboutUs-content-subPage'}>
                {renderSubPage()}
            </div>
        </div>
    );
}

export default AboutUs;