import React from 'react';
import './navBar.css';
import logo from '../../../assets/images/Logo SDI.png';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import {Link, withRouter} from 'react-router-dom';
import Cookies from 'js-cookie';

const windowWidth = window.innerWidth;

const NavBar = ({username, categoryList}) => (
    windowWidth>768?
        <div className={'navBar-wrapper pure-g'}>
            <div className={'pure-u-1-6'}>
                <Link to={'/'}>
                    <div className={'navBar-logo'}>
                        <img src={logo} className='pure-img navBar-logo-img' alt='logo'/>
                    </div>
                </Link>
            </div>
            <div className={'pure-u-2-3'}>
                <div className={'pure-menu-horizontal navBar-list'}>
                    <ul className="pure-menu-list">
                        <Link to={'/'} className="pure-menu-item navBar-list-item">
                            <div className={'navBar-list-item-text text-bold'}>Home</div>
                        </Link>
                        <Link to={'/activities'} className="pure-menu-item navBar-list-item pure-menu-has-children pure-menu-allow-hover">
                            <div className={'navBar-list-item-text text-bold'}>Activities</div>
                            <ul className="pure-menu-children navBar-list-item-dropdown">
                                <div className={'navBar-list-item-dropdown-box'}>
                                    {categoryList.map((category, index) => (
                                        <Link to={`/activities/${category}`} key={index} className="pure-menu-item navBar-list-item-dropdown-text text">{category}</Link>
                                    ))}
                                </div>
                            </ul>
                        </Link>
                        <Link to={'/about'} className="pure-menu-item navBar-list-item pure-menu-has-children pure-menu-allow-hover">
                            <div className={'navBar-list-item-text text-bold'}>About Us</div>
                            <ul className="pure-menu-children navBar-list-item-dropdown">
                                <div className={'navBar-list-item-dropdown-box'}>
                                    <Link to={'/about/background'} className="pure-menu-item navBar-list-item-dropdown-text text">Our Background</Link>
                                    <Link to={'/about/why-sdi'} className="pure-menu-item navBar-list-item-dropdown-text text">Why Choose SDI</Link>
                                    <Link to={'/about/directors'} className="pure-menu-item navBar-list-item-dropdown-text text">Board of Directors</Link>
                                    <Link to={'/about/members'} className="pure-menu-item navBar-list-item-dropdown-text text">Our Members</Link>
                                </div>
                            </ul>
                        </Link>
                    </ul>
                </div>
            </div>
            <div className={'pure-u-1-6'}>
                <div className={'navBar-account'}>
                    {Cookies.get('isSignedIn')==='true'?
                        <Link to={'/account'} className={'navBar-account-login'}>
                            <div className={'navBar-account-username-text text'}>{Cookies.get('username')}</div>
                            <Icon icon={"user-cog"} color={'#707070'}/>
                        </Link>
                        :
                        <div className={'navBar-account-login'}>
                            <Link to={'/login'} className={'navBar-account-login-text text-bold'}>Sign In</Link>
                            <Icon icon={"user-circle"} color={'#707070'}/>
                        </div>
                    }
                </div>
            </div>
        </div>
        :
        <div className={'navBar-wrapper pure-g'}>
            <div className={'pure-g navBar-mobile-wrapper'}>
                <div className={'pure-u-1-4'}>
                    <Link className={'navBar-button text'} to={'/'}>
                        <Icon icon={'home'} className={'navBar-icon'}/>
                        Home
                    </Link>
                </div>
                <div className={'pure-u-1-4'}>
                    <Link to={'/activities'} className={'navBar-button text'}>
                        <Icon icon={'newspaper'} className={'navBar-icon'}/>
                        Activities
                    </Link>
                </div>
                <div className={'pure-u-1-4'}>
                    <Link to={'/about'} className={'navBar-button text'}>
                        <Icon icon={'bullseye'} className={'navBar-icon'}/>
                        About Us
                    </Link>
                </div>
                <div className={'pure-u-1-4'}>
                    {Cookies.get('isSignedIn')==='true'?
                        <Link to={'/account'} className={'navBar-button text'}>
                            <Icon icon={'user'} className={'navBar-icon'}/>
                            Account
                        </Link>
                        :
                        <Link to={'/login'} className={'navBar-button text'}>
                            <Icon icon={'sign-in-alt'} className={'navBar-icon'}/>
                            Sign In
                        </Link>
                    }
                </div>
            </div>
        </div>

);

export default withRouter(NavBar);