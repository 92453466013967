import Cookies from 'js-cookie';

// Build Header
const buildHeader = async () => {
  // TODO: token cookie is not needed, use automatic response cookie
    // const isSignedIn = Cookies.get('isSignedIn');
    // if(isSignedIn === 'true'){
    //     let token = 'Bearer ' + Cookies.get('token');
    //     return {
    //         'Content-Type': 'application/json',
    //         'Authorization': token
    //     }
    // }else{
        return {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    // }
};

// Build Request
const buildRequest = async (method, body) =>{
    return {
        method: method,
        headers: await buildHeader(),
        body: JSON.stringify(body),
        credentials: 'include'
    }
};

// // Build Error
// const errorBuilder = (message,status) => {
//     return {
//         message: message,
//         status: status,
//     }
// };

const postService = async (url, body) => {
    let data = await buildRequest("POST",body);
    return await restService(url,data);
};

const getService = async (url,body) => {
    let data = await buildRequest("GET",body);
    return await restService(url,data);
};

const putService = async (url,body) => {
    let data = await buildRequest("PUT",body);
    return await restService(url,data);
};

const deleteService = async (url,body) => {
    let data = await buildRequest("DELETE",body);
    return await restService(url,data);
};

const customRequest = async (url,method,headers,body) =>{
    const defaultHeader = buildHeader();
    let data = {
        method: method,
        headers: {
            ...defaultHeader,
            ...headers
        },
        body: JSON.stringify(body)
    };
    return await restService(url,data);
};

const restService = async (url,data) =>{
    let result = null;
    // console.log(data);

    const response = await fetch(url,data);
    result = await response.json();

    // console.log(result);
    return result;
};

export {
    postService,
    getService,
    putService,
    deleteService,
    customRequest,
};