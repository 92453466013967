import React, {useEffect, useState} from "react";
import '../pages.css'
import {Loader, Modal} from '../../components'
import {getService} from '../../service/RestService';
import {URL_COMPANY_BY_ID, URL_PAGINATE_MEMBERS} from '../../service/url';
import {FontAwesomeIcon as Icon} from "@fortawesome/react-fontawesome";
import {Link} from "react-router-dom";
import Cookies from "js-cookie";

function Members (){
    //States
    const [members, setMembers] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [eligible, setEligible] = useState('Please Wait ...');
    const [page, setPage] = useState({});
    const [showModal, setShowModal] = useState(false);
    const [companyId, setCompanyId] = useState(1);
    const [showModalProfile, setShowModalProfile] = useState(false);
    const [modalProfileImgUrl, setModalProfileImgUrl] = useState();

    //Constructor
    useEffect(() => {
        async function mount(){
            await validateAccess()
        }
        mount();
    }, []);

    //Functions
    const validateAccess = async() => {
        const result = await getService(URL_PAGINATE_MEMBERS(1,5));
        setEligible(result.resultStatus);
        if(result.resultStatus.resultCode === 'SUCCESS') {
            setMembers(result.page.content);
            setPage(result.page);
        }
    };

    const fetchCompanyId = async(id) => {
        const result = await getService(URL_COMPANY_BY_ID(id));
        setCompanyId(result);
    };

    const handleCompanyClick = async(id) =>{
        await fetchCompanyId(id);
        toggleModal();
    };

    const toggleModal = () => {
        let change = (showModal !== true);
        setShowModal(change);
    };

    const toggleModalProfile = () => {
        let change = (showModalProfile !== true);
        setShowModalProfile(change);
    };

    const handleChangePage = async(direction) => {
        let pageChange;
        if(direction === 'next') pageChange = currentPage + 1;
        else pageChange = currentPage - 1;

        const result = await getService(URL_PAGINATE_MEMBERS(pageChange,5));
        setMembers(result.page.content);
        setPage(result.page);
        setCurrentPage(pageChange);
    };

    const windowWidth = window.innerWidth;
    return(
        <div className={'content'}>
            {!members?
                <div>
                    <div className={'premium-gate'}>
                        <div className={'premium-loader'}><Loader/></div>
                        {eligible.resultCode}
                        {eligible.resultCode==='FAILED'?
                            <div>
                                <div className={'text'} style={{textAlign:'center'}}>{eligible.message}</div>
                                <br/>
                                <Link to={'/account'} className={'premium-back text'} >
                                    <Icon icon={'caret-left'}/>
                                    <div style={{marginLeft:'1vh'}}>Go back to Account</div>
                                </Link>
                            </div>
                            :
                            null
                        }
                    </div>
                </div>
                :
                <div className={'premium-wrapper'}>
                    {/*MEMBERS' DATABASE*/}
                    <div className={'premium-title text-bold'}>MEMBER DATABASE</div>
                    {windowWidth>768?
                        <div className={'premium-table'}>
                            <div className={'premium-table-head pure-g'}>
                                <div className={'pure-u-5-24'}>
                                    <div className={'text-minor'} style={{letterSpacing:'0.1vw'}}>NAME</div>
                                </div>
                                <div className={'pure-u-1-6'}>
                                    <div className={'text-minor'} style={{letterSpacing:'0.1vw'}}>EMAIL</div>
                                </div>
                                <div className={'pure-u-1-6'}>
                                    <div className={'text-minor'} style={{letterSpacing:'0.1vw', textAlign:'right'}}>COMPANY</div>
                                </div>
                                <div className={'pure-u-1-8'}>
                                    <div className={'text-minor'} style={{letterSpacing:'0.1vw', textAlign:'right'}}>POSITION</div>
                                </div>
                                <div className={'pure-u-1-6'}>
                                    <div className={'text-minor'} style={{letterSpacing:'0.1vw', textAlign:'right'}}>PHONE</div>
                                </div>
                                <div className={'pure-u-1-6'}>
                                    <div className={'text-minor'} style={{letterSpacing:'0.1vw', textAlign:'right'}}>BIRTHDAY</div>
                                </div>
                            </div>
                            <div className={'premium-table-content'}>
                                {/*ROW*/}
                                {members.map(member => (
                                    <div className={'premium-table-row pure-g'} key={member.user.id}>
                                        <div className={'pure-u-5-24'}>
                                            {
                                                member.profileImgUrl?
                                                    <a className={'text'} href={member.profileImgUrl} target="_blank" style={{letterSpacing:'0.1vw', textDecoration: 'underline'}}>{member.firstName} {member.lastName}</a>
                                                    :
                                                    <div className={'text'} style={{letterSpacing:'0.1vw'}}>{member.firstName} {member.lastName}</div>
                                            }
                                        </div>
                                        <div className={'pure-u-1-6'}>
                                            <div className={'text'} style={{letterSpacing:'0.1vw'}}>{member.user.email}</div>
                                        </div>
                                        <div className={'pure-u-1-6'}>
                                            <div className={'text premium-data-company'} style={{letterSpacing:'0.1vw', textAlign:'right'}} onClick={() => {handleCompanyClick(member.company.id)}}>
                                                {member.company?member.company.companyName:' - '}
                                            </div>
                                        </div>
                                        <div className={'pure-u-1-8'}>
                                            <div className={'text'} style={{letterSpacing:'0.1vw', textAlign:'right'}}>{member.position?member.position:' - '}</div>
                                        </div>
                                        <div className={'pure-u-1-6'}>
                                            <div className={'text'} style={{letterSpacing:'0.1vw', textAlign:'right'}}>{member.contactNo?member.contactNo:' - '}</div>
                                        </div>
                                        <div className={'pure-u-1-6'}>
                                            <div className={'text'} style={{letterSpacing:'0.1vw', textAlign:'right'}}>{member.dob?member.dob.substring(0,10):' - '}</div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            {page.totalPages>1?
                                <div className={'premium-table-footer'}>
                                    <div className={'premium-table-footer-page text-minor'}>Page ( {currentPage}/{page.totalPages} )</div>
                                    <div className={'premium-table-footer-icons'}>
                                        {page.first? null : <Icon icon={'chevron-circle-left'} className={'premium-footer-icon'} onClick={()=>{handleChangePage('prev')}}/>}
                                        {page.last? null : <Icon icon={'chevron-circle-right'} className={'premium-footer-icon'} onClick={()=>{handleChangePage('next')}}/>}
                                    </div>
                                </div>
                                :null
                            }
                        </div>
                        :
                        <div className={'text articleP-restrict-content'}>
                            <br/>
                            This content can only be viewed on Desktop or Laptop devices, please use another device to open this page.
                            <br/><br/>
                            For more info please contact us at <span className={'text-bold'}>info@salesdirector.id</span>
                        </div>
                    }
                </div>
            }
            {/*MODAL COMPANY*/}
            <Modal show={showModal} title={companyId.companyDescription?(companyId.companyName + ' - ' + companyId.companyDescription):companyId.companyName} onClose={()=>{toggleModal()}}>
                {windowWidth>768?
                    <div className={'modal-compro'}>
                        <div className={'modal-compro-logo'}>
                            <img src={companyId.companyLogoUrl} alt=""/>
                        </div>
                        <div className={'modal-compro-detail'}>
                            <div className={'modal-compro-detail-public'}>
                                <div className={'modal-compro-texts text-bold'} style={{letterSpacing: '0.1vh'}}>{companyId.companyName}</div>
                                <div className={'modal-compro-texts text'}>{companyId.companyDescription}</div>
                                {companyId.companyWebUrl?
                                    <div className={'modal-compro-texts text'}><span>Website:</span> <a target="_blank" rel="noopener noreferrer" href={companyId.companyWebUrl}>{companyId.companyWebUrl}</a></div>
                                    :null
                                }
                                <div className={'modal-compro-texts text'}><span>Industry:</span> {companyId.companyIndustry}</div>
                                <div className={'modal-compro-texts text'}><span>Address:</span> {companyId.companyAddress}</div>
                            </div>
                            {Cookies.get('isSignedIn')==='true'?
                                <div className={'modal-compro-detail-private'}>
                                    <div className={'modal-compro-texts text-bold'}>CONTACTS</div>
                                    <div className={'modal-compro-texts text'}>{companyId.companyEmail}</div>
                                    <div className={'modal-compro-texts text'}>{companyId.companyContactNo}</div>
                                </div>
                                : null
                            }
                        </div>
                    </div>
                    :
                    <div className={'modal-compro'}>
                        <div className={'modal-compro-logo'}>
                            <img src={companyId.companyLogoUrl} alt=""/>
                        </div>
                        <div className={'modal-compro-detail'}>
                            <div className={'modal-compro-detail-public'}>
                                <div className={'modal-compro-texts text-bold'} style={{letterSpacing: '0.1vh'}}>{companyId.companyName}</div>
                                <div className={'modal-compro-texts text'}>{companyId.companyDescription}</div>
                                {companyId.companyWebUrl?
                                    <div className={'modal-compro-texts text'}><span>Website:</span> <a target="_blank" rel="noopener noreferrer" href={companyId.companyWebUrl}>{companyId.companyWebUrl}</a></div>
                                    :null
                                }
                                <div className={'modal-compro-texts text'}><span>Industry:</span> {companyId.companyIndustry}</div>
                                <div className={'modal-compro-texts text'}><span>Address:</span> {companyId.companyAddress}</div>
                            </div>
                            <div className={'modal-compro-detail-private'}>
                                <div className={'modal-compro-texts text-bold'}>CONTACTS</div>
                                <div className={'modal-compro-texts text'}>{companyId.companyEmail}</div>
                                <div className={'modal-compro-texts text'}>{companyId.companyContactNo}</div>
                            </div>
                        </div>
                    </div>
                }
            </Modal>
        </div>
    )
}

export default Members;