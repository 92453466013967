import React from "react";
import './modal.css'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';


const Modal = ({show, title, children, onClose}) => (
    <div>
        {show?
        <div className={'modal-bg'}>
            <div className={'modal-box'}>
                <div className={'modal-header'}>
                    <div className={'modal-title text-bold'}>{title}</div>
                    <div className={'modal-close'} onClick={onClose}>
                        <Icon icon={'window-close'} color={'#eb292f'} className={'modal-close-icon'}/>
                    </div>
                </div>
                <div className={'modal-content'}>
                    {children}
                </div>
            </div>
        </div>
        :null}
    </div>
);

export default Modal;