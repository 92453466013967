import React, {useState, useEffect} from "react";
import '../../pages.css';
import {URL_COMPANY_BY_ID, URL_PAGINATE_COMPANY_LOGO} from '../../../service/url'
import {getService} from '../../../service/RestService'
import Cookies from 'js-cookie';
import {Modal} from "../../../components/Box/Modal";

function OurMembers(){
    //States
    const [showModalCompany, setShowModalCompany] = useState(false);
    const [companyLogos, setCompanyLogos] = useState([]);
    const [companyId, setCompanyId] = useState(1);

    //Constructor
    useEffect(()=>{
        async function mount(){
            await fetchCompanyLogos();
        }
        mount();
    },[]);

    //Functions
    const fetchCompanyLogos = async() => {
        const result = await getService(URL_PAGINATE_COMPANY_LOGO(1, 100));
        setCompanyLogos(result.page.content);
    };
    const fetchCompanyId = async(id) => {
        const result = await getService(URL_COMPANY_BY_ID(id));
        setCompanyId(result);
    };
    const handleCompanyClick = async(id) =>{
        await fetchCompanyId(id);
        toggleModal();
    };
    const toggleModal = () => {
        let change = (showModalCompany !== true);
        setShowModalCompany(change);
    };

    const windowWidth = window.innerWidth;
    return(
        <div>
            {companyLogos.length>0?
                <div className={'home-members'}>
                    <div className={'bod-section-title text-bold'}>Our Members</div>
                    <div className="text-minor" style={{textAlign:'center'}}>Click logo for more details</div>
                    <div className={'home-members-content pure-g'}>
                        {companyLogos.map(company => (
                            <div className={windowWidth>768?'pure-u-1-8':'pure-u-1-2'} key={company.id}>
                                <div className={'home-members-logo'} onClick={() => {handleCompanyClick(company.id)}}>
                                    <img src={company.companyLogoUrl} alt="logoBF"/>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                :null
            }
            {/*Modal Company*/}
            {companyLogos.length>0?
                <Modal show={showModalCompany} title={companyId.companyDescription?(companyId.companyName):companyId.companyName} onClose={()=>{toggleModal()}}>
                    {windowWidth>768?
                        <div className={'modal-compro'}>
                            <div className={'modal-compro-logo'}>
                                <img src={companyId.companyLogoUrl} alt=""/>
                            </div>
                            <div className={'modal-compro-detail'}>
                                <div className={'modal-compro-detail-public'}>
                                    <div className={'modal-compro-texts text-bold'} style={{letterSpacing: '0.1vh'}}>{companyId.companyName}</div>
                                    <div className={'modal-compro-texts text'}>{companyId.companyDescription}</div>
                                    {companyId.companyWebUrl?
                                        <div className={'modal-compro-texts text'}><span>Website:</span> <a target="_blank" rel="noopener noreferrer" href={companyId.companyWebUrl}>{companyId.companyWebUrl}</a></div>
                                        :null
                                    }
                                    <div className={'modal-compro-texts text'}><span>Industry:</span> {companyId.companyIndustry}</div>
                                    <div className={'modal-compro-texts text'}><span>Address:</span> {companyId.companyAddress}</div>
                                </div>
                                {Cookies.get('isSignedIn')==='true'?
                                    <div className={'modal-compro-detail-private'}>
                                        <div className={'modal-compro-texts text-bold'}>CONTACTS</div>
                                        <div className={'modal-compro-texts text'}>{companyId.companyEmail}</div>
                                        <div className={'modal-compro-texts text'}>{companyId.companyContactNo}</div>
                                    </div>
                                    : null
                                }
                            </div>
                        </div>
                        :
                        <div className={'modal-compro'}>
                            <div className={'modal-compro-logo'}>
                                <img src={companyId.companyLogoUrl} alt=""/>
                            </div>
                            <div className={'modal-compro-detail'}>
                                <div className={'modal-compro-detail-public'}>
                                    <div className={'modal-compro-texts text-bold'} style={{letterSpacing: '0.1vh'}}>{companyId.companyName}</div>
                                    <div className={'modal-compro-texts text'}>{companyId.companyDescription}</div>
                                    {companyId.companyWebUrl?
                                        <div className={'modal-compro-texts text'}><span>Website:</span> <a target="_blank" rel="noopener noreferrer" href={companyId.companyWebUrl}>{companyId.companyWebUrl}</a></div>
                                        :null
                                    }
                                    <div className={'modal-compro-texts text'}><span>Industry:</span> {companyId.companyIndustry}</div>
                                    <div className={'modal-compro-texts text'}><span>Address:</span> {companyId.companyAddress}</div>
                                </div>
                                {Cookies.get('isSignedIn')==='true'?
                                    <div className={'modal-compro-detail-private'}>
                                        <div className={'modal-compro-texts text-bold'}>CONTACTS</div>
                                        <div className={'modal-compro-texts text'}>{companyId.companyEmail}</div>
                                        <div className={'modal-compro-texts text'}>{companyId.companyContactNo}</div>
                                    </div>
                                    : null
                                }
                            </div>
                        </div>
                    }
                </Modal>
                :null
            }
        </div>
    )
}

export default OurMembers;