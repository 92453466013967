import React, {useState, useEffect} from "react";
import './pages.css'
import {NavBar, Footer} from '../components';
import Cookies from 'js-cookie';

import {URL_ALL_CATEGORY} from '../service/url';
import {getService} from '../service/RestService';

import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import {Home, NotFound} from './Home';
import {AboutUs} from './AboutUs';
import {PrivacyPolicy} from './PrivacyPolicy';
import {ArticleList, CategoryList} from './ArticleList';
import {ArticlePage} from './Article';
import {Login, Account, ResetPass} from './Auth';
import {Files, Members, Premium} from './Premium';

function Navigation (){
    //State
    const [username, setUsername] = useState(Cookies.get('username'));
    const [categories, setCategories] = useState([]);

    //Constructor
    useEffect(() => {
        async function mount(){
            await fetchCategory()
        }
        mount();
        setUsername(Cookies.get('username'));
    },[]);

    //Function
    const fetchCategory = async() => {
        const result = await getService(URL_ALL_CATEGORY);
        let listCategory = result.categories;
        let listCategoryName = [];
        for (const category of listCategory){
            listCategoryName.push(category.categoryName)
        }
        setCategories(listCategoryName);
    };

    return(
        <Router>
            <div className={'wrapper'}>
                {/*harus buat refresh abis sign in & sign out*/}
                <NavBar username={username} categoryList={categories}/>

                <Switch>
                    <Route path={'/'} exact component={Home}/>
                    <Route path={'/about'} exact component={AboutUs}/>
                    <Route path={'/about/:page'} component={AboutUs}/>
                    <Route path={'/activities'} exact component={ArticleList}/>
                    <Route path={'/activities/:category'} exact component={CategoryList}/>
                    {/*<Route path={'/activities/:category/:page'} exact component={CategoryList}/>*/}
                    <Route path={'/article'} exact component={ArticlePage}/>
                    <Route path={'/article/:slug'} exact component={ArticlePage}/>
                    <Route path={'/login'} exact component={Login}/>
                    {/*harus buat redirect ke account page kalo udh logged in*/}
                    <Route path={'/account'} exact component={Account}/>
                    <Route path={'/reset-password'} exact component={ResetPass}/>
                    <Route path={'/reset-password/:id'} component={ResetPass}/>
                    <Route path={'/premium/'} exact component={Premium}/>
                    <Route path={'/premium/files'} exact component={Files}/>
                    <Route path={'/premium/members'} exact component={Members}/>
                    <Route path={'/privacy'} exact component={PrivacyPolicy}/>
                    <Route path={'/404'} exact component={NotFound}/>
                    <Route path='*' exact component={NotFound}/>
                </Switch>

                <Footer/>
            </div>
        </Router>
    );
}

export default Navigation;