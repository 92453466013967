import React, {useEffect, useState} from "react";
import '../pages.css'
import {ImageBox, Modal} from '../../components';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import {URL_ARTICLE_DETAIL, URL_PAGINATE_ARTICLE_CATEGORY, URL_COMPANY_BY_ID} from '../../service/url';
import {getService} from '../../service/RestService';
import {Link} from "react-router-dom";

function ArticlePage ({match, history}){
    //Constructor
    useEffect(()=>{
        async function mount(){
            setCompanyProfile(null);
            await validateSlug();
            await fetchNextArticle()
        }
        mount();
    },[match]);

    //State
    const [articleContent, setArticleContent] = useState(null);
    const [indexMedia, setIndexMedia] = useState(0);
    const [showMediaModal, setShowMediaModal] = useState(false);
    const [companyProfile, setCompanyProfile] = useState(null);
    const [nextArticle,setNextArticle] = useState([]);

    //Function
    const validateSlug = async() => {
        if(!match.params.slug) history.push('/404');
        else await fetchArticle()
    };
    const fetchArticle = async() => {
        const result = await getService(URL_ARTICLE_DETAIL(match.params.slug));
        setArticleContent(result);
        if(result.promotedCompanyId){await fetchCompany(result.promotedCompanyId)}
    };
    const fetchCompany = async(id) => {
        const result = await getService(URL_COMPANY_BY_ID(id));
        setCompanyProfile(result)
    };
    const fetchNextArticle = async() => {
        let category = (Math.floor(Math.random() * Math.floor(4)) + 1);
        let page = (Math.floor(Math.random() * Math.floor(3)) + 1);
        const result = await getService(URL_PAGINATE_ARTICLE_CATEGORY(page,1,category));
        setNextArticle(result.page.content);
    };

    const renderContent = () => {
        return <div dangerouslySetInnerHTML={{__html: articleContent.content}} />;
    };
    const handleClickMedia = (index) => {
      setIndexMedia(index);
      toggleModal();
    };
    const toggleModal = () => {
        let change = (showMediaModal !== true);
        setShowMediaModal(change);
    };

    const windowWidth = window.innerWidth;
    return(
        articleContent===null?
            null
            :
            <div>
                <div className={'content'}>
                    {/*Article*/}
                    <div className={'articleP-box'}>
                        <div className={'articleP-header'}>
                            <div className={'articleP-header-text text-minor'}>{articleContent.gmtCreate.substring(0,10)}</div>
                            <Link to={`/activities/${articleContent.category.categoryName}`} className={'articleP-header-text text-minor'}>
                                <span>#{articleContent.category.categoryName}</span>
                            </Link>
                        </div>
                        <div className={'articleP-title text'}>{articleContent.title}</div>
                        {articleContent.media.viewableItems>0?
                            <div className={'articleP-medias pure-g'}>
                                {articleContent.media.items.map((media,index) => (
                                    windowWidth>768?
                                        <div className={'pure-u-1-3'} key={media.id}>
                                            <div className={'articleP-media'} onClick={()=>{handleClickMedia(index)}}>
                                                <ImageBox shadow={true} styles={{width:'100%', height:'100%'}} src={media.mediaType==='video'?youtubeIcon:media.mediaUrl}/>
                                            </div>
                                        </div>
                                        :
                                        <div className={'pure-u-1'} key={media.id}>
                                            <a className={'articleP-media'} href={media.mediaUrl.substring(media.mediaUrl.indexOf('/'))}>
                                                <ImageBox shadow={true} styles={{width:'100%', height:'100%'}} src={media.mediaType==='video'?youtubeIcon:media.mediaUrl}/>
                                            </a>
                                        </div>
                                ))}
                            </div>
                            :null
                        }
                        <div className={'articleP-content text'}>
                            {articleContent.content?
                                renderContent()
                                :
                                <div>
                                    <div className={'articleP-restrict'}>
                                        {articleContent.contentPreview}
                                        <div className={'articleP-restrict-box'}>
                                            <div className={'text articleP-restrict-content'}>
                                                For full content, please login. For SDI members only.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                    {/*Footer*/}
                    <div className={'articleP-footer'} style={companyProfile? {justifyContent:'space-between'}:{justifyContent:'flex-end'}}>
                        {companyProfile?
                            //Company
                            <div className={'articleP-company'}>
                                <div className={'pure-g'}>
                                    <div className={windowWidth>768?'pure-u-1-4':'pure-u-1'}>
                                        <div className={'articleP-company-logo'}>
                                            <img src={companyProfile.companyLogoUrl} alt=""/>
                                        </div>
                                    </div>
                                    <div className={windowWidth>768?'pure-u-3-4':'pure-u-1'}>
                                        <div className={'articleP-company-details pure-g'}>
                                            <div className={windowWidth>768?'pure-u-1-2':'pure-u-1'}>
                                                <div className={'articleP-company-public'}>
                                                    <div className={'articleP-company-title text-bold'}>{companyProfile.companyName}</div>
                                                    <div className={'articleP-company-subtitle text'}>{companyProfile.companyDescription}</div>
                                                    <div className={'articleP-company-subtitle text'}>Industry: <br/>{companyProfile.companyIndustry}</div>
                                                    <div className={'articleP-company-subtitle text'}>Address: <br/>{companyProfile.companyAddress}</div>
                                                </div>
                                            </div>
                                            <div className={windowWidth>768?'pure-u-1-2':'pure-u-1'}>
                                                <div className={'articleP-company-private'}>
                                                    <div className={'articleP-company-title text-bold'}>Contact Us</div>
                                                    <div className={'articleP-company-subtitle text'} style={windowWidth>768?{textAlign:'right'}:null}>{companyProfile.companyEmail}</div>
                                                    <div className={'articleP-company-subtitle text'} style={windowWidth>768?{textAlign:'right'}:null}>{companyProfile.companyContactNo}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :null
                        }
                        {nextArticle.length>0?
                            //Next Article
                            <Link className={'articleP-next'} to={`/article/${nextArticle[0].urlSlug}`}>
                                <div className={'articleP-next-text'}>
                                    <div className={'articleP-next-article text'}>Next Article</div>
                                    <div className={'articleP-next-title text-bold'}>{nextArticle[0].title}</div>
                                </div>
                                <Icon icon={'chevron-circle-right'} color={'white'} className={'articleP-next-icon'}/>
                            </Link>
                            :null
                        }
                    </div>
                </div>
                {/*MODAL*/}
                {articleContent.media.viewableItems>0?
                    <Modal show={showMediaModal} onClose={()=>{toggleModal()}}>
                        <div className={'articleP-modal'}>
                            {articleContent.media.items[indexMedia].mediaType==='image'?
                                <div className={'articleP-modal-img'}>
                                    <div className={'text'} style={{margin:'1vh 0'}}>Click image for a full view</div>
                                    <ImageBox shadow={true} link={articleContent.media.items[indexMedia].mediaUrl} src={articleContent.media.items[indexMedia].mediaUrl}/>
                                </div>
                                :
                                <div className={'articleP-modal-video'}>
                                    <iframe src={articleContent.media.items[indexMedia].mediaUrl}
                                            width={'100%'}
                                            height={'100%'}
                                            frameBorder='0'
                                            allow='autoplay; encrypted-media'
                                            allowFullScreen
                                            title='video'
                                    />
                                </div>
                            }
                        </div>
                    </Modal>
                    :null
                }
            </div>
    );
}

export default ArticlePage;
const youtubeIcon = 'https://lh3.googleusercontent.com/z6Sl4j9zQ88oUKNy0G3PAMiVwy8DzQLh_ygyvBXv0zVNUZ_wQPN_n7EAR2By3dhoUpX7kTpaHjRPni1MHwKpaBJbpNqdEsHZsH4q';