import Cookies from 'js-cookie';
import {getService, postService} from './RestService';
import {URL_FORGET_PASSWORD, URL_LOGIN, URL_LOGOUT, URL_VALIDATE_LOGIN} from './url'

const loginCheck = async() => {
    const result = await getService(URL_VALIDATE_LOGIN);

    if (result.resultStatus.resultCode === 'SUCCESS'){
        Cookies.set('isSignedIn', result.isSignedIn, { expires: 2 });
        Cookies.set('username', result.username, {expires: 2});
        Cookies.set('email', result.email, {expires: 2});
    }
    else {
        console.log(result.resultStatus.message);
    }
};

const handleLogin = async (body) => {
    const result = await postService(URL_LOGIN,body);

    if (result.resultStatus.resultCode === 'SUCCESS'){
      // TODO: token cookie is not needed, use automatic response cookie
        // Cookies.set('token', result.token, { expires: 2 });
        Cookies.set('isSignedIn', true, { expires: 2 });
        await loginCheck();
    }
    else {
        console.log(result.resultStatus.message);
    }

    return result;
};
const handleLogout = async () => {
  // Cookies.remove('token');
  await postService(URL_LOGOUT, {});

  Cookies.set('isSignedIn', false);
  Cookies.remove('username');

  Cookies.remove('isLoggedIn');
  Cookies.remove('isLoggedin');
};

const postForgetPassword = async(body) => {
    return await postService(URL_FORGET_PASSWORD, body);
};

export {
    loginCheck,
    handleLogin,
    handleLogout,
    postForgetPassword
};